@import "variables";

@mixin text-d1-regular {
  font-size: 68px;
  line-height: 82px;
  font-weight: 400;
}

@mixin text-d1-medium {
  font-size: 68px;
  line-height: 82px;
  font-weight: 500;
}

@mixin text-d1-bold {
  font-size: 68px;
  line-height: 82px;
  font-weight: 700;
}

@mixin text-d2-regular {
  font-size: 56px;
  line-height: 78px;
  font-weight: 400;
}

@mixin text-d2-medium {
  font-size: 56px;
  line-height: 78px;
  font-weight: 500;
}

@mixin text-d2-bold {
  font-size: 56px;
  line-height: 78px;
  font-weight: 700;
}

/* H1 */

@mixin text-h1-regular {
  font-size: 48px;
  line-height: 58px;
  font-weight: 400;
}

@mixin text-h1-medium {
  font-size: 48px;
  line-height: 58px;
  font-weight: 500;
}

@mixin text-h1-bold {
  font-size: 48px;
  line-height: 58px;
  font-weight: 700;
}

/* H2 */

@mixin text-h2-regular {
  font-size: 40px;
  line-height: 48px;
  font-weight: 400;
}

@mixin text-h2-medium {
  font-size: 40px;
  line-height: 48px;
  font-weight: 500;
}

@mixin text-h2-bold {
  font-size: 40px;
  line-height: 48px;
  font-weight: 700;
}

/* H3 */

@mixin text-h3-regular {
  font-size: 32px;
  line-height: 38px;
  font-weight: 400;
}

@mixin text-h3-medium {
  font-size: 32px;
  line-height: 38px;
  font-weight: 500;
}

@mixin text-h3-bold {
  font-size: 32px;
  line-height: 38px;
  font-weight: 700;
}

/* H4 */

@mixin text-h4-regular {
  font-size: 28px;
  line-height: 34px;
  font-weight: 400;
}

@mixin text-h4-medium {
  font-size: 28px;
  line-height: 34px;
  font-weight: 500;
}

@mixin text-h4-bold {
  font-size: 28px;
  line-height: 34px;
  font-weight: 700;
}

/* H5 */

@mixin text-h5-regular {
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
}

@mixin text-h5-medium {
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
}

@mixin text-h5-bold {
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
}

/* H6 */

@mixin text-h6-regular {
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
}

@mixin text-h6-medium {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
}

@mixin text-h6-bold {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
}

/* P1 */

@mixin text-p1-regular {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

@mixin text-p1-medium {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

@mixin text-p1-bold {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}

/* P2 */

@mixin text-p2-regular {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

@mixin text-p2-medium {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

@mixin text-p2-bold {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
}

/* Caption */

@mixin text-caption-regular {
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
}

@mixin text-caption-medium {
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
}

@mixin text-caption-bold {
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
}

/* Over Text  Uppercase */

@mixin text-overtext-uppercase-regular {
  font-size: 10px;
  line-height: 14px;
  font-weight: 400;
  text-transform: uppercase;
}

@mixin text-overtext-uppercase-medium {
  font-size: 10px;
  line-height: 14px;
  font-weight: 500;
  text-transform: uppercase;
}

@mixin text-overtext-uppercase-bold {
  font-size: 10px;
  line-height: 14px;
  font-weight: 700;
  text-transform: uppercase;
}

/* Over Text */

@mixin text-overtext-regular {
  font-size: 10px;
  line-height: 14px;
  font-weight: 400;
}

@mixin text-overtext-medium {
  font-size: 10px;
  line-height: 14px;
  font-weight: 500;
}

@mixin text-overtext-bold {
  font-size: 10px;
  line-height: 14px;
  font-weight: 700;
}

/* --------------------------- */

/* Basic  OLD - used  */

@mixin text-base {
  font-size: 16px;
  line-height: 24px;
}

@mixin text-base-bold {
  @include text-base();
  font-weight: bold;
}

@mixin text-xs {
  font-size: 12px;
  line-height: 14px;
}

@mixin text-xxs {
  font-size: 10px;
  line-height: 12px;
}

@mixin text-xs-bold {
  @include text-xs();
  font-weight: bold;
}

@mixin text-sm {
  font-size: 14px;
  line-height: 20px;
}

@mixin text-sm-bold {
  @include text-sm();
  font-weight: bold;
}

@mixin text-lg {
  font-size: 18px;
  line-height: 28px;
}

@mixin text-lg-bold {
  @include text-lg();
  font-weight: bold;
}

@mixin text-xl {
  font-size: 24px;
  line-height: 32px;
}

@mixin text-xl-bold {
  @include text-xl();
  font-weight: bold;
}

@mixin text-ellipsis {
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@mixin text-break {
  word-break: break-word;
  white-space: pre-line;
}

/* Links */

@mixin link-hover {
  color: $primary-light;
  text-decoration: none;
  outline: none;
}

@mixin link-active {
  color: $primary-dark;
  text-decoration: none;
  outline: none;
}

/* Headings */
@mixin large-heading {
  @include text-xl();
  font-weight: bold;
  color: $gray-700;

  @media only screen and (max-width: $break-xs) {
    font-size: 18px;
  }
}

@mixin small-heading {
  @include text-sm();
  font-weight: bold;
  color: $gray-700;
}

@mixin text-heading {
  @include text-lg-bold();
  color: $gray-700;
}
