@import "partials/resets.scss";
@import "partials/buttons.scss";
@import "partials/forms.scss";
@import "partials/modals.scss";
@import "partials/tables.scss";
@import "partials/shadows.scss";
@import "partials/toast.scss";
@import "partials/grid.scss";
@import "partials/margins.scss";
@import "partials/flexbox.scss";
@import "partials/scrollbar.scss";

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");

html,
body {
  font-family: Inter, sans-serif, Arial;
  font-style: normal;
  @include text-sm();
  color: $gray-900;
  background-color: $gray-50;
}

footer {
  display: none;
}

a,
a:visited {
  color: $primary-normal;
  text-decoration: none;
  outline: none;
}

a:hover {
  @include link-hover();
}

a:active {
  @include link-active();
}

.break {
  word-break: break-word;
  white-space: pre-line;
}

.break-word {
  word-break: break-word;
  white-space: pre-line;
}

hr {
  height: 0;
  border: 0;
  border-top: 1px solid $gray-100;
}

.icon {
  display: block;
  float: left;
  width: 40px;
  height: 40px;
  margin-right: 3px;
  line-height: 40px;
  background-size: cover;

  &.icon-small {
    width: 20px;
    height: 20px;
    line-height: 20px;
  }

  &.icon-info {
    background-image: url("../../assets/tabler-icons/info-circle.svg");
  }
}

.large-heading {
  @include large-heading();
}

.small-heading {
  @include small-heading();
}

.spinner {
  width: 100%;
  text-align: center;
  margin: 32px 0;
  color: $primary-normal;
}

.text-ellipsis {
  @include text-ellipsis();
}

.site-container {
  display: flex;
  align-items: stretch;
  margin-top: 65px;
  min-height: calc(100vh - 65px);

  .container-col {
    position: relative;
    width: 100%;
    margin-left: 200px;
    transition: margin 0.3s;

    @media only screen and (max-width: $break-md) {
      margin-left: 0;
    }

    &.closed {
      margin-left: 75px;
      transition: margin 0.3s;

      @media only screen and (max-width: $break-md) {
        margin-left: 0;
      }

      .profile-fixed-bottom,
      .project-container .fixed-bottom {
        left: 75px;
        transition: margin 0.3s;

        @media only screen and (max-width: $break-md) {
          left: 0;
        }
      }

      .project-container.draft .fixed-bottom,
      .project-container.wizard .fixed-bottom {
        left: 375px;
        transition: margin 0.3s;

        @media only screen and (max-width: $break-md) {
          left: 0;
        }
      }

      .my-suppliers-container .fixed-bottom,
      .fixed-bottom.suppliers {
        left: 375px;
        transition: margin 0.3s;

        @media only screen and (max-width: $break-lg) {
          left: 75px;
        }

        @media only screen and (max-width: $break-md) {
          left: 0;
        }
      }

      .mobile-suppliers-menu {
        margin-left: -75px;
      }
    }

    @media only screen and (max-width: $break-md) {
      margin-left: 0;
      transition: margin 0.3s;
    }

    @media only screen and (max-width: $break-xs) {
      margin-left: 0;
      transition: margin 0.3s;
    }

    .sustainment-wrapper,
    .sustainment-body {
      max-width: 1440px;
      margin: 0 auto;
      padding: 24px 24px 0;

      @media only screen and (max-width: $break-md) {
        padding: 24px;
      }
    }

    .sustainment-header {
      display: flex;
      padding-bottom: 24px;
      justify-content: space-between;
      align-items: center;

      @media only screen and (max-width: $break-sm) {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 12px;
      }
    }
  }
}

body {
  &.block {
    overflow: hidden;
  }
}

button,
.p-button {
  &.primary {
    @include primary-button();

    &:enabled:hover {
      @include primary-button-hover();
    }

    &:enabled:active {
      @include primary-button-active();
    }

    &:enabled:focus {
      @include primary-button-focus();
    }

    &:disabled {
      @include primary-button-disabled();
    }

    &.small {
      @include small-button();
    }

    &.large {
      @include large-button();
    }

    &.red {
      @include primary-red-skin-button();

      &:enabled:hover {
        @include primary-red-skin-button-hover();
      }

      &:enabled:active {
        @include primary-red-skin-button-active();
      }

      &:enabled:focus {
        @include primary-red-skin-button-focus();
      }

      &.small {
        @include small-button();
      }

      &.large {
        @include large-button();
      }
    }

    &.green {
      @include primary-green-skin-button();

      &:enabled:hover {
        @include primary-green-skin-button-hover();
      }

      &:enabled:active {
        @include primary-green-skin-button-active();
      }

      &:enabled:focus {
        @include primary-green-skin-button-focus();
      }

      &.small {
        @include small-button();
      }

      &.large {
        @include large-button();
      }
    }

    &.orange {
      @include primary-orange-skin-button();

      &:enabled:hover {
        @include primary-orange-skin-button-hover();
      }

      &:enabled:active {
        @include primary-orange-skin-button-active();
      }

      &:enabled:focus {
        @include primary-orange-skin-button-focus();
      }

      &.small {
        @include small-button();
      }

      &.large {
        @include large-button();
      }
    }
  }

  &.info {
    @include tertiary-button();

    &.blue {
      @include primary-info-skin-button();

      &:enabled:hover {
        @include primary-info-skin-button-hover();
      }

      &:enabled:active {
        @include primary-info-skin-button-active();
      }

      &:enabled:focus {
        @include primary-info-skin-button-focus();
      }

      &.small {
        @include small-button();
      }

      &.large {
        @include large-button();
      }
    }
  }

  &.secondary {
    @include secondary-button();

    &:enabled:hover {
      @include secondary-button-hover();
    }

    &:enabled:active {
      @include secondary-button-active();
    }

    &:enabled:focus {
      @include secondary-button-focus();
    }

    &:disabled {
      @include secondary-button-disabled();
    }

    &.small {
      @include small-button();
    }

    &.large {
      @include large-button();
    }

    &.red {
      @include secondary-red-skin-button();

      &:enabled:hover {
        @include secondary-red-skin-button-hover();
      }

      &:enabled:active {
        @include secondary-red-skin-button-active();
      }

      &:enabled:focus {
        @include secondary-red-skin-button-focus();
      }

      &.small {
        @include small-button();
      }

      &.large {
        @include large-button();
      }
    }

    &.green {
      @include secondary-green-skin-button();

      &:enabled:hover {
        @include secondary-green-skin-button-hover();
      }

      &:enabled:active {
        @include secondary-green-skin-button-active();
      }

      &:enabled:focus {
        @include secondary-green-skin-button-focus();
      }

      &.small {
        @include small-button();
      }

      &.large {
        @include large-button();
      }
    }

    &.orange {
      @include secondary-orange-skin-button();

      &:enabled:hover {
        @include secondary-orange-skin-button-hover();
      }

      &:enabled:active {
        @include secondary-orange-skin-button-active();
      }

      &:enabled:focus {
        @include secondary-orange-skin-button-focus();
      }

      &.small {
        @include small-button();
      }

      &.large {
        @include large-button();
      }
    }
  }

  /* Text buttons */
  &.tertiary {
    @include tertiary-button();

    &:enabled:hover {
      @include tertiary-button-hover();
    }

    &:enabled:active {
      @include tertiary-button-active();
    }

    &:enabled:focus {
      @include tertiary-button-focus();
    }

    &:disabled {
      @include tertiary-button-disabled();
    }

    &.small {
      @include small-button();
    }

    &.large {
      @include large-button();
    }

    &.red {
      @include tertiary-red-skin-button();

      &:enabled:hover {
        @include tertiary-red-skin-button-hover();
      }

      &:enabled:active {
        @include tertiary-red-skin-button-active();
      }

      &:enabled:focus {
        @include tertiary-red-skin-button-focus();
      }

      &.small {
        @include small-button();
      }

      &.large {
        @include large-button();
      }
    }

    &.green {
      @include tertiary-green-skin-button();

      &:enabled:hover {
        @include tertiary-green-skin-button-hover();
      }

      &:enabled:active {
        @include tertiary-green-skin-button-active();
      }

      &:enabled:focus {
        @include tertiary-green-skin-button-focus();
      }

      &.small {
        @include small-button();
      }

      &.large {
        @include large-button();
      }
    }

    &.orange {
      @include tertiary-orange-skin-button();

      &:enabled:hover {
        @include tertiary-orange-skin-button-hover();
      }

      &:enabled:active {
        @include tertiary-orange-skin-button-active();
      }

      &:enabled:focus {
        @include tertiary-orange-skin-button-focus();
      }

      &.small {
        @include small-button();
      }

      &.large {
        @include large-button();
      }
    }
  }

  &.small {
    @include small-button();

    &.p-button-icon-only {
      @include icon-button();
    }

    .p-button-icon-left {
      margin-right: 10px;
    }

    .p-button-icon {
      @include text-xs();
    }
  }

  &.large {
    @include large-button();

    &.p-button-icon-only {
      @include icon-button-large();
    }

    .p-button-icon {
      @include text-base();
    }
  }

  &.p-button-icon-only {
    @include icon-button();
  }

  .p-button-icon-left {
    margin-right: 12px;

    &.null {
      margin-right: 0;
    }
  }

  .p-button-icon-right {
    margin-left: 8px;
  }

  /* Dropdown buttons */
  &.filter-option {
    margin: 3px 7px 3px 3px;

    @include dropdown-button();

    &:enabled:hover {
      @include dropdown-button-hover();
    }

    &:enabled:active,
    &.active {
      @include dropdown-button-active();

      &:enabled:hover {
        border: 1px solid $primary-normal;
        color: $gray-500;
      }
    }

    &.small {
      @include small-button();
    }

    &.has-value {
      @include dropdown-button-hasvalue();

      &:enabled:hover {
        border: 1px solid $primary-normal;
        color: $primary-normal;
      }

      &.active {
        border: 1px solid $primary-normal;
        background-color: #fff;
        color: $gray-500;

        &:enabled:hover {
          border: 1px solid $primary-normal;
          color: $gray-500;
        }
      }
    }
  }
}

.filter-option {
  .p-button {
    @include dropdown-button();

    &:enabled:hover {
      @include dropdown-button-hover();
    }

    &.small {
      @include small-button();
    }

    &:enabled:active {
      @include dropdown-button-active();
    }
  }

  &.active {
    .p-button {
      @include dropdown-button-active();
    }
  }

  &.has-value {
    .p-button {
      @include dropdown-button-hasvalue();

      &.active {
        border: 1px solid $primary-normal;
        background-color: transparent;
        color: $gray-500;
      }
    }
  }
}

/* Inputs */
input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
textarea,
.p-inputtext {
  @include input-style();
  margin-bottom: 0;

  &::placeholder {
    color: $gray-500;
  }

  &:enabled:hover {
    @include input-style-hover();
  }

  &:enabled:focus,
  &:enabled:focus:hover {
    @include input-style-focus();
  }

  &.ng-invalid {
    @include input-style-invalid();

    &.ng-untouched {
      @include input-style();
    }
  }

  &:disabled {
    @include input-style-disabled();
  }

  &.small {
    i {
      font-size: 12px;
    }

    input {
      @include input-style-small();
    }

    .p-input-icon-left {
      input {
        padding-left: 32px;
      }
    }

    .p-input-icon-right {
      input {
        padding-right: 32px;
        width: calc(100% - 46px);
      }
    }
  }

  &.large {
    i {
      font-size: 16px;
    }

    input {
      @include input-style-large();
    }

    .p-input-icon-left {
      input {
        padding-left: 38px;
      }
    }

    .p-input-icon-right {
      input {
        padding-right: 38px;
      }
    }
  }
}

p-inputnumber {
  &.ng-invalid {
    &.ng-touched {
      .p-inputtext {
        @include input-style-invalid();
      }
    }
  }
}

.text-danger {
  color: $danger-normal !important;
}

.input-container,
.p-field {
  padding-bottom: 8px;
  padding-left: 2px;
  padding-right: 2px;

  input,
  textarea {
    width: 100%;
    margin-bottom: 8px;
  }

  p-inputnumber {
    .p-inputnumber {
      width: 100%;
      margin-bottom: 8px;
    }
  }

  label {
    margin-bottom: 2px;

    &.text-danger {
      color: $danger-normal !important;
    }

    .required {
      color: $danger-normal;
    }
  }
}

.input-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;

  input,
  textarea {
    @include input-style();
    margin-bottom: 0;

    &::placeholder {
      color: $gray-500;
    }

    &:enabled:hover {
      @include input-style-hover();
    }

    &:enabled:focus,
    &:enabled:focus:hover {
      @include input-style-focus();
    }

    &.ng-invalid {
      @include input-style-invalid();

      &.ng-untouched {
        @include input-style();
      }
    }

    &:disabled {
      @include input-style-disabled();
    }
  }

  &.small {
    i {
      font-size: 12px;
    }

    input {
      @include input-style-small();
    }

    .p-input-icon-left {
      input {
        padding-left: 32px;
      }
    }

    .p-input-icon-right {
      input {
        padding-right: 32px;
      }
    }
  }

  &.large {
    i {
      font-size: 16px;
    }

    input {
      @include input-style-large();
    }

    .p-input-icon-left {
      input {
        padding-left: 38px;
      }
    }

    .p-input-icon-right {
      input {
        padding-right: 38px;
      }
    }
  }

  &.ng-invalid {
    input {
      @include input-style-invalid();
    }

    &.ng-untouched {
      input {
        @include input-style();
      }
    }
  }

  label {
    margin-bottom: 2px;

    &.text-danger {
      color: $danger-normal !important;
    }

    &.text-warn {
      color: $attention-normal !important;
    }
  }

  .error-text,
  .text-danger,
  .text-warn {
    margin-top: 2px;
    color: $danger-normal;
    @include text-sm();
  }

  .p-input-icon-left {
    display: block;

    > i {
      left: 12px;
    }

    input {
      padding-left: 34px;
    }
  }

  .p-input-icon-right {
    display: block;

    > i {
      right: 12px;
    }

    input {
      padding-right: 34px;
      width: calc(100% - 46px);
    }
  }
}

select {
  @include select-style();

  &:enabled:hover {
    @include input-style-hover();
  }

  &:enabled:focus,
  &:enabled:focus:hover {
    @include input-style-focus();
  }

  &.ng-invalid {
    @include input-style-invalid();

    &.ng-untouched {
      @include select-style();
    }
  }

  &:disabled {
    @include input-style-disabled();
  }

  &.small {
    @include input-style-small();
  }

  &.large {
    @include input-style-large();
  }
}

label {
  @include text-sm();
  margin-bottom: 2px;
}

/* Confirm Popup */

.p-confirm-popup {
  box-shadow: none;
  filter: drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.06))
    drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.04))
    drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.04));
  background: $white;

  &:before,
  &:after {
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: $white;
  }

  &.p-confirm-popup-flipped {
    &:before,
    &:after {
      border-top-color: $white;
    }
  }

  .p-confirm-popup-icon {
    font-size: 1.5rem;
  }

  .p-confirm-popup-message {
    margin-left: 1rem;
  }

  &.cancel-confirmpopup {
    width: 320px;

    .p-confirm-popup-content {
      padding: 12px;

      .p-confirm-popup-message {
        @include text-sm();
      }

      .p-confirm-popup-icon {
        &.pi-question {
          color: $primary-normal;
        }

        &.pi-trash {
          color: $danger-normal;
        }
      }
    }

    .p-confirm-popup-footer {
      padding: 4px 12px 12px;
      text-align: right;

      .p-button {
        @include text-xs();

        &.p-button-text {
          @include tertiary-button();
          @include small-button();
          margin-right: 8px;

          &:enabled:hover {
            @include tertiary-button-hover();
          }

          &:enabled:active {
            @include tertiary-button-active();
          }
        }

        &.p-confirm-popup-accept {
          @include primary-button();
          @include small-button();

          &:enabled:hover {
            @include primary-button-hover();
          }

          &:enabled:active {
            @include primary-button-active();
          }
        }
      }
    }
  }
}

/* Overlay Sidebar*/

.p-sidebar {
  width: 360px !important;
  background: $gray-50;
  box-shadow: 0px -10px 20px rgba(0, 0, 0, 0.08);

  &.p-sidebar-left {
    .p-sidebar-footer {
      justify-content: flex-start;
    }
  }

  &.p-sidebar-bottom {
    width: 100% !important;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    height: auto;
  }

  .p-sidebar-header {
    position: absolute;
    right: 18px;
    top: 16px;
    z-index: 999;
  }

  .p-sidebar-title {
    display: block;
    @include text-h6-bold();
    border-bottom: 1px solid $gray-100;
    padding-bottom: 12px;
    margin: 12px 32px 0 16px;
    word-break: break-word;
  }

  .p-sidebar-footer {
    margin-top: auto;
    display: flex;
    justify-content: flex-end;
    column-gap: 12px;
    padding: 12px;
  }

  .p-sidebar-body {
    padding: 12px;

    .items-list {
      .items-container {
        margin-bottom: 8px;
        padding: 0 2px;
      }

      .header-row {
        padding: 0 24px 16px 0;
        border-bottom: 1px solid #f3f4f6;
        margin-bottom: 12px;
        color: $gray-800;
        overflow: hidden;
        text-overflow: ellipsis;
        content: "";

        span {
          font-weight: bold;
          font-size: 14px;
          line-height: 20px;
        }
      }

      .add-link {
        span {
          color: $primary-normal;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    .add-new-item {
      margin-top: 16px;
      margin-bottom: 4px;
    }
  }
}

.p-component-overlay {
  &.p-sidebar-mask {
    background: $gray-900;
    opacity: 0.4;
  }
}

/* hotfix for sidebar mask not hiding after sidebar close */

.p-component-overlay-enter {
  animation: p-component-overlay-enter-animation 150ms forwards;
}

.p-component-overlay-leave {
  animation: p-component-overlay-leave-animation 150ms forwards;
}

@keyframes p-component-overlay-enter-animation {
  from {
    background-color: transparent;
  }

  to {
    background-color: var($gray-900);
  }
}

@keyframes p-component-overlay-leave-animation {
  from {
    background-color: var($gray-900);
  }

  to {
    background-color: transparent;
  }
}

/* Menu */
.p-menu {
  .p-submenu-header {
    padding: 0;
  }

  .p-menuitem-link {
    padding: 8px 16px;

    .p-menuitem-icon {
      margin-right: 4px;
      color: $gray-500;
    }

    .p-menuitem-text {
      @include text-sm();
      color: $gray-800;
    }
  }

  &.p-menu-overlay {
    filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.04))
      drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.04))
      drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.04));
    border-radius: 8px;
    box-shadow: none;
    background: $white;
  }

  &.kebab-menu {
    &.p-menu-overlay {
      min-width: 180px;

      .p-menuitem-link {
        text-align: left;

        &:hover {
          background-color: $gray-50;
        }
      }
    }
  }
}

/* Avatar */

.p-avatar {
  background-color: $bg-primary;
  border-radius: 8px;
  color: $primary-normal;
  width: 28px !important;
  height: 28px !important;
  font-size: 14px !important;
  border: 1px solid $gray-200;

  &.p-avatar-image {
    img {
      border-radius: 8px;
    }
  }

  .p-avatar-icon {
    font-size: 14px !important;
    line-height: 20px !important;
  }

  &.p-avatar-lg {
    width: 42px !important;
    height: 42px !important;
    font-size: 16px !important;
    border-radius: 8px;

    .p-avatar-icon {
      font-size: 20px !important;
    }
  }

  &.p-avatar-xl {
    width: 56px !important;
    height: 56px !important;
    font-size: 24px !important;

    .p-avatar-icon {
      font-size: 28px !important;
    }
  }

  &.p-avatar-multiple {
    border-bottom: 2px solid $primary-normal;
    border-right: 2px solid $primary-normal;
  }
}

.p-avatar-group .p-avatar {
  border: 2px solid $gray-200;
}

/* Badge */

.p-badge {
  font-family: Inter;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  font-style: normal;
  background: $gray-300;
  color: $gray-900;
  font-size: 12px;
  border-radius: 50% !important;
  min-width: 24px;
  height: 24px;
  padding: 0 !important;
}

.p-overlay-badge {
  .p-badge {
    min-width: 20px;
    height: 20px;
    border: 2px solid #fff;
    font-size: 10px;

    &.p-badge-success,
    &.p-badge-danger {
      bottom: 0;
      top: auto;
      transform: translate(50%, 50%);
    }
  }

  &.p-badge-empty {
    .p-badge {
      min-width: 15px;
      height: 15px;
    }
  }
}

.p-badge.p-badge-secondary {
  background-color: #607d8b;
  color: #ffffff;
}

.p-badge.p-badge-success {
  background-color: #689f38;
  color: #ffffff;
}

.p-badge.p-badge-info {
  background-color: $primary-normal;
  color: #ffffff;
}

.p-badge.p-badge-warning {
  background-color: #fbc02d;
  color: #212529;
}

.p-badge.p-badge-danger {
  background-color: $gray-300;
  color: $gray-900;
}

.p-badge.p-badge-lg {
  font-size: 1.125rem;
  min-width: 2.25rem;
  height: 2.25rem;
  line-height: 2.25rem;
}

.p-badge.p-badge-xl {
  font-size: 1.5rem;
  min-width: 3rem;
  height: 3rem;
  line-height: 3rem;
}

.p-buttonset {
  .p-button {
    &:first-of-type {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-of-type {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

/* SelectButton */
.p-selectbutton {
  .p-button {
    @include text-sm();
    background: $white;
    border: 1px solid $gray-300;
    color: $gray-800;
    padding: 8px 16px;
    border-radius: 3px;

    &.p-select i,
    i {
      margin-right: 4px;
    }

    .p-button-icon-left,
    .p-button-icon-right {
      color: $gray-800;
    }

    &:not(.p-disabled):not(.p-highlight):hover {
      background: #e9ecef;
      border-color: #ced4da;
      color: $gray-800;

      .p-button-icon-left,
      .p-button-icon-right {
        color: $gray-600;
      }
    }

    &.p-highlight {
      background: $primary-normal;
      border-color: $primary-normal;
      color: $white;

      .p-button-icon-left,
      .p-button-icon-right {
        color: $white;
      }

      &:hover {
        background: $primary-light;
        border-color: $primary-light;
        color: $white;

        .p-button-icon-left,
        .p-button-icon-right {
          color: $white;
        }
      }
    }
  }
}

/* Overlay Panel */
.p-overlaypanel {
  background: #ffffff;
  color: $gray-900;
  border: 0 none;
  border: 1px solid $gray-200;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 6px;

  &:after {
    content: none;
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: $white;
  }

  &:before {
    content: none;
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: $gray-200;
  }

  &:before {
    border-width: 10px;
    margin-left: -10px;
  }

  &:after {
    border-width: 8px;
    margin-left: -8px;
  }

  .p-overlaypanel-content {
    padding: 16px;
  }

  .p-overlaypanel-close {
    background: $primary-normal;
    color: #ffffff;
    width: 2rem;
    height: 2rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 50%;
    position: absolute;
    top: -1rem;
    right: -1rem;

    &:enabled:hover {
      background: $primary-dark;
      color: #ffffff;
    }
  }

  &.p-overlaypanel-flipped {
    margin-top: -8px !important;

    &:after {
      border-top-color: $white;
    }

    :before {
      border-top-color: $gray-200;
    }
  }

  &.profile-overlay {
    margin-top: 0 !important;

    &:before,
    &:after {
      display: none;
    }
  }

  &.filters-overlay {
    margin-top: 1px !important;
  }

  &.word-break-all {
    word-break: break-all;
  }

  &.gray-overlay {
    background-color: $gray-900;
    color: $gray-100;
    opacity: 0.9 !important;
    border-color: transparent;
    z-index: 1092 !important;

    &:after {
      border-bottom-color: $gray-900;
    }

    &:before {
      border-bottom-color: $gray-900;
    }

    .p-overlaypanel-content {
      max-width: 280px;
    }

    .title {
      font-weight: bold;
    }

    &.p-overlaypanel-flipped {
      &:after {
        border-top-color: $gray-900;
      }

      &:before {
        border-top-color: $gray-900;
      }
    }
  }

  &.project-limit-overlay {
    z-index: 1092 !important;
    width: 300px;
  }

  &.no-arrow-panel {
    margin-top: 2px;

    &:after {
      border: none;
      border-color: transparent;
      border-bottom-color: transparent;
    }

    &:before {
      border: none;
      border-color: transparent;
      border-bottom-color: transparent;
    }
  }

  &.expert-tip {
    width: 590px;
    border-radius: 12px;

    .p-overlaypanel-content {
      padding: 24px;
    }
  }

  &.no-arrow-panel-small-menu {
    width: 200px;

    .p-overlaypanel-content {
      padding: 0;
    }
  }
}

/* Tooltip */
.p-tooltip .p-tooltip-text {
  @include text-sm();
  background: $gray-900;
  opacity: 0.9;
  color: $white;
  padding: 12px 16px;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
  border-radius: 6px;
}

.p-tooltip.p-tooltip-right {
  padding-left: 9px;
}

.p-tooltip.p-tooltip-left {
  padding: 0 9px 0 0;
}

.p-tooltip.p-tooltip-right {
  padding: 0 0 0 9px;
}

.p-tooltip.p-tooltip-top {
  padding: 0 0 9px 0;
}

.p-tooltip.p-tooltip-bottom {
  padding: 9px 0 0 0;
}

.p-tooltip .p-tooltip-arrow {
  opacity: 0.9;
}

.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: $gray-900;
  margin-top: -6px;
  border-width: 6px 6px 6px 0;
}

.p-tooltip.p-tooltip-left .p-tooltip-arrow {
  border-left-color: $gray-900;
  margin-top: -6px;
  border-width: 6px 0 6px 6px;
}

.p-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: $gray-900;
  margin-left: -6px;
  border-width: 6px 6px 0;
}

.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: $gray-900;
  border-width: 0 6px 6px;
  margin-left: -6px;
}

/* TabView */
.p-tabview {
  .p-tabview-nav {
    border-bottom: 1px solid $gray-300;

    li {
      margin-right: 0;

      .p-tabview-nav-link {
        color: $gray-800;
        padding: 20px 32px 16px 32px;
        @include text-sm();
        font-weight: 400;
        transition: box-shadow 0.2s;
        border-bottom: 4px solid transparent;
        border-radius: 6px 6px 0px 0px;

        p-badge {
          display: flex;
          align-items: center;

          .p-badge {
            @include text-xxs();
            min-width: 15px;
            height: 15px;
          }
        }

        .p-tabview-title {
          line-height: 26px;
        }

        @media only screen and (max-width: $break-xs) {
          padding: 16px 8px;
        }

        .tab-header {
          display: flex;
          align-items: center;
          column-gap: 8px;
        }
      }

      &:not(.p-highlight):not(.p-disabled):hover {
        .p-tabview-nav-link {
          border-color: $primary-dark;
          background-color: $gray-100;
        }
      }

      &.p-highlight {
        .p-tabview-nav-link {
          border-color: $primary-dark;
          color: $primary-dark;
          background-color: $bg-secondary;
          font-weight: 700;

          .p-badge {
            background: $primary-dark;
            color: #fff;
          }
        }
      }

      &.p-disabled {
        .p-tabview-nav-link {
          color: $gray-500;
        }
      }
    }

    .p-tabview-left-icon,
    .p-tabview-right-icon {
      margin-right: 8px;
    }

    .p-tabview-close {
      margin-left: 4px;
    }
  }

  .p-tabview-panels {
    border: 0 none;

    .p-tabview-panel {
      padding: 24px 0;
    }
  }
}

/* Accordion */
.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 1rem;
  border: 1px solid #dee2e6;
  color: #495057;
  background: #f8f9fa;
  font-weight: 600;
  border-radius: 3px;
  transition: box-shadow 0.2s;
}

.p-accordion
  .p-accordion-header
  .p-accordion-header-link
  .p-accordion-toggle-icon {
  margin-right: 0.5rem;
}

.p-accordion
  .p-accordion-header:not(.p-disabled)
  .p-accordion-header-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-accordion
  .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
  .p-accordion-header-link {
  background: #e9ecef;
  border-color: #dee2e6;
  color: #495057;
}

.p-accordion
  .p-accordion-header:not(.p-disabled).p-highlight
  .p-accordion-header-link {
  background: #f8f9fa;
  border-color: #dee2e6;
  color: #495057;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.p-accordion
  .p-accordion-header:not(.p-disabled).p-highlight:hover
  .p-accordion-header-link {
  border-color: #dee2e6;
  background: #e9ecef;
  color: #495057;
}

.p-accordion .p-accordion-content {
  padding: 1rem;
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-accordion p-accordiontab .p-accordion-tab {
  margin-bottom: 0;
}

.p-accordion p-accordiontab .p-accordion-header .p-accordion-header-link {
  border-radius: 0;
}

.p-accordion p-accordiontab .p-accordion-content {
  border-radius: 0;
}

.p-accordion
  p-accordiontab:not(:first-child)
  .p-accordion-header
  .p-accordion-header-link {
  border-top: 0 none;
}

.p-accordion
  p-accordiontab:not(:first-child)
  .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
  .p-accordion-header-link,
.p-accordion
  p-accordiontab:not(:first-child)
  .p-accordion-header:not(.p-disabled).p-highlight:hover
  .p-accordion-header-link {
  border-top: 0 none;
}

.p-accordion
  p-accordiontab:first-child
  .p-accordion-header
  .p-accordion-header-link {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.p-accordion
  p-accordiontab:last-child
  .p-accordion-header:not(.p-highlight)
  .p-accordion-header-link {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-accordion p-accordiontab:last-child .p-accordion-content {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

/* picklist */

.p-picklist .p-picklist-buttons {
  padding: 1rem;
}

.p-picklist .p-picklist-buttons .p-button {
  margin-bottom: 0.5rem;
}

.p-picklist .p-picklist-header {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #dee2e6;
  padding: 1rem;
  border-bottom: 0 none;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.p-picklist .p-picklist-header .p-picklist-title {
  font-weight: 600;
}

.p-picklist .p-picklist-filter-container {
  padding: 1rem;
  background: #ffffff;
  border: 1px solid #dee2e6;
  border-bottom: 0 none;
}

.p-picklist .p-picklist-filter-container .p-picklist-filter-input {
  padding-right: 1.5rem;
}

.p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
  right: 0.5rem;
  color: #6c757d;
}

.p-picklist .p-picklist-list {
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  padding: 0.5rem 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-picklist .p-picklist-list .p-picklist-item {
  padding: 0.5rem 1rem;
  margin: 0;
  border: 0 none;
  color: #495057;
  background: transparent;
  transition: transform 0.2s, box-shadow 0.2s;
}

.p-picklist .p-picklist-list .p-picklist-item:not(.p-highlight):hover {
  background: #e9ecef;
  color: #495057;
}

.p-picklist .p-picklist-list .p-picklist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #a6d5fa;
}

.p-picklist .p-picklist-list .p-picklist-item.p-highlight {
  color: #495057;
  background: #e3f2fd;
}

.p-picklist
  .p-picklist-list
  .p-picklist-droppoint.p-picklist-droppoint-highlight {
  background-color: #89c8f7;
}

.p-picklist .p-picklist-list .p-picklist-empty-message {
  padding: 0.5rem 1rem;
  color: #495057;
}

@media screen and (max-width: 769px) {
  .p-picklist {
    flex-direction: column;
  }

  .p-picklist .p-picklist-buttons {
    padding: 1rem;
    flex-direction: row;
  }

  .p-picklist .p-picklist-buttons .p-button {
    margin-right: 0.5rem;
    margin-bottom: 0;
  }

  .p-picklist .p-picklist-buttons .p-button:last-child {
    margin-right: 0;
  }

  .p-picklist .p-picklist-transfer-buttons .pi-angle-right:before {
    content: "";
  }

  .p-picklist .p-picklist-transfer-buttons .pi-angle-double-right:before {
    content: "";
  }

  .p-picklist .p-picklist-transfer-buttons .pi-angle-left:before {
    content: "";
  }

  .p-picklist .p-picklist-transfer-buttons .pi-angle-double-left:before {
    content: "";
  }
}

.p-inputswitch {
  width: 3rem;
  height: 1.75rem;

  .p-inputswitch-slider {
    background: $gray-300;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
      box-shadow 0.2s;
    border-radius: 30px;

    &:before {
      background: #ffffff;
      width: 1.25rem;
      height: 1.25rem;
      left: 0.25rem;
      margin-top: -0.625rem;
      border-radius: 50%;
      transition-duration: 0.2s;
    }
  }

  &.p-inputswitch-small {
    width: 35px;
    height: 20px;

    .p-inputswitch-slider:before {
      width: 15px;
      height: 15px;
      margin-top: -7.5px;
    }

    &.p-inputswitch-checked {
      .p-inputswitch-slider {
        &:before {
          transform: translateX(13px);
        }
      }
    }
  }

  &.p-inputswitch-large {
    width: 48px;
    height: 28px;

    .p-inputswitch-slider:before {
      width: 20px;
      height: 20px;
      margin-top: -10px;
    }

    &.p-inputswitch-checked {
      .p-inputswitch-slider {
        &:before {
          transform: translateX(21px);
        }
      }
    }
  }
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  transform: translateX(1.25rem);
}

.p-inputswitch.p-focus .p-inputswitch-slider {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem $primary-lighter;
}

.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
  background: $gray-300;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: $primary-normal;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background: #ffffff;
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover
  .p-inputswitch-slider {
  background: $primary-normal;
}

p-inputswitch.ng-dirty.ng-invalid > .p-inputswitch {
  border-color: #f44336;
}

.p-orderlist .p-orderlist-controls {
  padding: 1rem;
}

.p-orderlist .p-orderlist-controls .p-button {
  margin-bottom: 0.5rem;
}

.p-orderlist .p-orderlist-header {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #dee2e6;
  padding: 1rem;
  border-bottom: 0 none;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.p-orderlist .p-orderlist-header .p-orderlist-title {
  font-weight: 600;
}

.p-orderlist .p-orderlist-filter-container {
  padding: 1rem;
  background: #ffffff;
  border: 1px solid #dee2e6;
  border-bottom: 0 none;
}

.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-input {
  padding-right: 1.5rem;
}

.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-icon {
  right: 0.5rem;
  color: #6c757d;
}

.p-orderlist .p-orderlist-list {
  background: #ffffff;
  color: #495057;
  padding: 0.5rem 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-orderlist .p-orderlist-list .p-orderlist-item {
  padding: 16px;
  margin: 0;
  margin-bottom: 8px;
  border: 1px solid $gray-200;
  color: $gray-800;
  border-radius: 4px;
  background: transparent;
  transition: transform 0.2s, box-shadow 0.2s;
}

.p-orderlist .p-orderlist-list .p-orderlist-item:not(.p-highlight):hover {
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
}

.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
  color: #495057;
  background: $primary-lighter;
  border: 2px solid $primary-normal;
}

.p-orderlist
  .p-orderlist-list
  .p-orderlist-droppoint.p-orderlist-droppoint-highlight {
  background-color: #89c8f7;
}

@media screen and (max-width: 769px) {
  .p-orderlist {
    flex-direction: column;
  }

  .p-orderlist .p-orderlist-controls {
    padding: 1rem;
    flex-direction: row;
  }

  .p-orderlist .p-orderlist-controls .p-button {
    margin-right: 0.5rem;
    margin-bottom: 0;
  }

  .p-orderlist .p-orderlist-controls .p-button:last-child {
    margin-right: 0;
  }
}

/* Chip */

.p-chip {
  background-color: $gray-500;
  color: $white;
  border-radius: 32px;
  padding: 3px 6px;

  &.primary {
    background: $primary-normal;
  }

  &.info {
    background: $information-normal;
  }

  &.danger {
    background: $danger-normal;
  }

  &.warning {
    background: $attention-normal;
  }

  &.success {
    background: $success-normal;
  }

  &.nda {
    background: $gray-900;
  }

  &.gray {
    background: $gray-700;
    color: $white;
  }

  &.approved {
    background: $bg-success2;
    color: $success-dark;

    .p-chip-text {
      font-weight: 700;
    }
  }

  &.onboarding {
    background: $bg-attention2;
    color: $attention-dark;

    .p-chip-text {
      font-weight: 700;
    }
  }

  &.donotuse {
    background: $bg-danger2;
    color: $danger-dark;

    .p-chip-text {
      font-weight: 700;
    }
  }

  .p-chip-text {
    @include text-xs();
    font-weight: 500;
  }

  .p-chip-icon {
    margin-right: 0.5rem;
  }

  .pi-chip-remove-icon {
    margin-left: 7px;
    @include text-xxs();
    font-weight: 700;
  }

  img {
    width: 2rem;
    height: 2rem;
    margin-left: -0.5rem;
    margin-right: 0.5rem;
  }

  .pi-chip-remove-icon {
    border-radius: 3px;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem #a6d5fa;
    }
  }
}

/* Carousel */

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  width: 2rem;
  height: 2rem;
  color: $primary-normal;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin: 4px;
}

.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
.p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
  color: $primary-dark;
  border-color: transparent;
  background: #e9ecef;
}

.p-carousel .p-carousel-content .p-carousel-prev:focus,
.p-carousel .p-carousel-content .p-carousel-next:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem $primary-light;
}

.p-carousel .p-carousel-indicators {
  padding: 1rem;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  background-color: #e9ecef;
  width: 1rem;
  height: 1rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
  background: $primary-hover;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background: $primary-normal;
  color: $primary-normal;
}

.p-disabled {
  opacity: 0.4;
}

.load {
  position: absolute;
  background-color: $gray-50;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  z-index: 1000;

  .loading-logo {
    animation: pulse 2s linear infinite;
    height: 200px;
  }

  @keyframes pulse {
    0% {
      filter: drop-shadow(0 0 4px $gray-500);
    }

    50% {
      filter: drop-shadow(0 0 30px $gray-500);
    }

    100% {
      filter: drop-shadow(0 0 4px $gray-500);
    }
  }
}

.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  transition-duration: 0.2s;
}

/* Paginator */
.p-paginator {
  color: #6c757d;
  padding: 0.5rem 1rem;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  background-color: transparent;
  border: 0 none;
  color: #6c757d;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0.143rem;
  transition: box-shadow 0.2s;
}

.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
  background: #e9ecef;
  border-color: transparent;
  color: #495057;
}

.p-paginator .p-paginator-first {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-paginator .p-paginator-last {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.p-paginator .p-dropdown {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.p-paginator .p-paginator-current {
  background-color: transparent;
  border: 0 none;
  color: #6c757d;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0.143rem;
  padding: 0 0.5rem;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  background-color: transparent;
  border: 0 none;
  color: #6c757d;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0.143rem;
  transition: box-shadow 0.2s;
  border-radius: 3px;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #e3f2fd;
  border-color: #e3f2fd;
  color: #495057;
}

.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  background: #e9ecef;
  border-color: transparent;
  color: #495057;
}

/* Multiselect */
.p-multiselect {
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  border-radius: 3px;
}

.p-multiselect:not(.p-disabled):hover {
  border-color: $primary-normal;
}

.p-multiselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
  border-color: $primary-normal;
}

.p-multiselect .p-multiselect-label {
  padding: 0.5rem 0.5rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
}

.p-multiselect .p-multiselect-label.p-placeholder {
  color: #6c757d;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 0.25rem 0.5rem;
  margin-right: 0.5rem;
  background: #e3f2fd;
  color: #495057;
  border-radius: 3px;
}

.p-multiselect.p-multiselect-chip
  .p-multiselect-token
  .p-multiselect-token-icon {
  margin-left: 0.5rem;
}

.p-multiselect .p-multiselect-trigger {
  background: transparent;
  color: #6c757d;
  width: 2.357rem;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.p-inputwrapper-filled .p-multiselect.p-multiselect-chip .p-multiselect-label {
  padding: 0.25rem 0.5rem;
}

.p-multiselect-panel {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 3px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-multiselect-panel .p-multiselect-header {
  padding: 0.5rem 1rem;
  border-bottom: 0 none;
  color: #495057;
  background: #f8f9fa;
  margin: 0;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.p-multiselect-panel
  .p-multiselect-header
  .p-multiselect-filter-container
  .p-inputtext {
  padding-right: 1.5rem;
}

.p-multiselect-panel
  .p-multiselect-header
  .p-multiselect-filter-container
  .p-multiselect-filter-icon {
  right: 0.5rem;
  color: #6c757d;
}

.p-multiselect-panel .p-multiselect-header .p-checkbox {
  margin-right: 0.5rem;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
  margin-left: 0.5rem;
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-multiselect-panel .p-multiselect-items:not(.p-multiselect-virtualscroll) {
  padding: 0.5rem 0;
}

.p-multiselect-panel
  .p-multiselect-items.p-multiselect-virtualscroll
  .cdk-virtual-scroll-content-wrapper {
  padding: 0.5rem 0;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  margin: 0;
  padding: 0.5rem 1rem;
  border: 0 none;
  color: #495057;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 0;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #495057;
  background: #e3f2fd;
}

.p-multiselect-panel
  .p-multiselect-items
  .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: #495057;
  background: #e9ecef;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #a6d5fa;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
  margin-right: 0.5rem;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #495057;
  background: #ffffff;
  font-weight: 600;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
  padding: 0.5rem 1rem;
  color: #495057;
  background: transparent;
}

p-multiselect.ng-dirty.ng-invalid > .p-multiselect {
  border-color: #f44336;
}

.p-input-filled .p-multiselect {
  background: #f8f9fa;
}

.p-input-filled .p-multiselect:not(.p-disabled):hover {
  background-color: #f8f9fa;
}

.p-input-filled .p-multiselect:not(.p-disabled).p-focus {
  background-color: #ffffff;
}

.p-inputtext {
  font-size: 1rem;
  color: #495057;
  background: #ffffff;
  padding: 0.5rem 0.5rem;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  appearance: none;
  border-radius: 3px;
}

.p-inputtext:enabled:hover {
  border-color: #2196f3;
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
  border-color: #2196f3;
}

.p-inputtext.ng-dirty.ng-invalid {
  border-color: #f44336;
}

.p-inputtext.p-inputtext-sm {
  font-size: 0.875rem;
  padding: 0.4375rem 0.4375rem;
}

.p-inputtext.p-inputtext-lg {
  font-size: 1.25rem;
  padding: 0.625rem 0.625rem;
}

/* Calendar */
p-calendar {
  &.ng-invalid {
    &.ng-touched {
      .p-calendar {
        border-color: $danger-normal;
      }
    }
  }
}

.p-calendar {
  &.p-calendar-w-btn {
    @include input-style();
    padding: 0;

    &:hover {
      @include input-style-hover();
    }

    &.p-focus {
      @include input-style-focus();
    }

    input {
      border: 0 none;

      &:hover {
        border: none 0;
      }

      &:enabled:focus {
        border: 0 none !important;
        box-shadow: none;
      }
    }

    .p-datepicker-trigger {
      background-color: transparent;

      .p-button-icon {
        color: $primary-normal;
      }
    }
  }
}

.p-datepicker {
  padding: 20px;
  background: #ffffff;
  color: $gray-700;
  border: 1px solid $gray-300;
  border-radius: 8px;
  margin-top: 4px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04);

  .p-datepicker-header {
    color: $gray-900;
    background: #ffffff;
    font-weight: 500;
    margin: 0;

    .p-datepicker-prev,
    .p-datepicker-next {
      width: 24px;
      height: 24px;
      color: $primary-normal;

      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }

    .p-datepicker-prev:enabled:hover,
    .p-datepicker-next:enabled:hover {
      border: 1px solid $primary-normal;
      background-color: $cold-smoke;
    }

    .p-datepicker-prev:enabled:focus,
    .p-datepicker-next:enabled:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem #bbdefb;
    }

    .p-datepicker-title {
      line-height: 2rem;

      select {
        transition: background-color 0.2s, color 0.2s, border-color 0.2s,
          box-shadow 0.2s;

        &:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #bbdefb;
          border-color: $primary-normal;
        }
      }

      .p-datepicker-month {
        margin-right: 0.5rem;
      }
    }
  }

  table {
    font-size: 1rem;
    margin: 0.5rem 0;

    th {
      padding: 0.5rem;
      color: $gray-500;

      > span {
        width: 2.5rem;
        height: 2.5rem;
      }
    }

    td {
      padding: 2px;

      > span {
        width: 36px;
        height: 36px;
        border-radius: 8px;
        transition: box-shadow 0.2s;
        border: 1px solid transparent;

        &.p-highlight {
          background: $primary-normal;
          border: 1px solid $primary-normal;
          color: #fff;
        }

        &:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #a6d5fa;
        }
      }

      &.p-datepicker-today {
        > span {
          background: $cold-smoke;
          color: $gray-700;
          border-color: $primary-normal;

          &.p-highlight {
            background-color: $primary-normal;
            color: $white;
          }
        }
      }
    }
  }

  .p-datepicker-buttonbar {
    padding: 1rem 0;
    border-top: 1px solid #dee2e6;

    .p-button {
      width: auto;
    }
  }

  .p-timepicker {
    border-top: 1px solid #dee2e6;
    padding: 0.5rem;

    button {
      width: 2rem;
      height: 2rem;
      color: #6c757d;
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

      &:enabled:hover {
        color: #495057;
        border-color: transparent;
        background: #e9ecef;
      }

      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #a6d5fa;
      }

      &:last-child {
        margin-top: 0.2em;
      }
    }

    span {
      font-size: 1.25rem;
    }

    > div {
      padding: 0 0.5rem;
    }
  }

  &.p-datepicker-timeonly .p-timepicker {
    border-top: 0 none;
  }

  .p-monthpicker {
    margin: 0.5rem 0;

    .p-monthpicker-month {
      padding: 2px;
      transition: box-shadow 0.2s;
      border-radius: 8px;
      border: 1px solid transparent;

      &.p-highlight {
        background: $cold-smoke;
        border: 1px solid $primary-normal;
      }
    }
  }

  &.p-datepicker-multiple-month {
    .p-datepicker-group {
      border-right: 1px solid #dee2e6;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      padding-top: 0;
      padding-bottom: 0;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
        border-right: 0 none;
      }
    }
  }

  &:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
    background: $cold-smoke;
    border: 1px solid $primary-normal;
    color: $gray-900;
  }

  &:not(.p-disabled)
    .p-monthpicker
    .p-monthpicker-month:not(.p-highlight):not(.p-disabled):hover {
    background: $cold-smoke;
    border: 1px solid $primary-normal;
  }
}

@media screen and (max-width: 769px) {
  .p-datepicker table th,
  .p-datepicker table td {
    padding: 0;
  }
}

.p-rating .p-rating-icon {
  color: #f0a339;
  margin-left: 0.5rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  font-size: 1.143rem;
}

.p-rating .p-rating-icon.p-rating-cancel {
  color: #e74c3c;
}

.p-rating .p-rating-icon:first-child {
  margin-left: 0;
}

.p-rating .p-rating-icon.pi-star {
  color: $gray-600;
}

.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon:hover {
  color: #f0a339;
}

.p-rating:not(.p-disabled):not(.p-readonly)
  .p-rating-icon.p-rating-cancel:hover {
  color: #c0392b;
}

p-rating {
  &.ng-invalid {
    .p-rating .p-rating-icon {
      &.pi-star {
        color: $danger-normal;
      }
    }
  }
}

/* Editor */
p-editor {
  .p-editor-container {
    .p-editor-toolbar {
      border: 1px solid $gray-200;
    }
  }

  &.ng-touched {
    &.ng-invalid {
      .p-editor-container {
        .p-editor-toolbar,
        .p-editor-content {
          border-color: $danger-normal;
        }
      }
    }
  }

  &.p-editor-view {
    .p-editor-container {
      .p-editor-toolbar {
        display: none;
      }

      .p-editor-content {
        &.ql-container {
          border: none;

          .ql-editor {
            padding: 0;
          }
        }
      }
    }
  }

  /* overwrite general quill css */
  .ql-container {
    font-family: Inter, sans-serif, Arial;
    font-style: normal;
    @include text-sm();
    color: $gray-900;

    &.ql-snow {
      border: 1px solid $gray-200;
    }

    .ql-editor {
      strong {
        font-weight: 700;
      }

      em {
        font-style: italic;
      }
    }
  }
}

.p-1 {
  padding: 1rem;
}

.bulleted li {
  padding-left: 1rem;
  list-style-type: disc;
  list-style-position: inside;
}

.pi-archive {
  background-image: url("../../assets/tabler-icons/archive.svg");
  width: 16px;
  height: 16px;
  background-size: 16px 16px;
  filter: brightness(0) saturate(100%) invert(50%) sepia(5%) saturate(1106%)
    hue-rotate(182deg) brightness(86%) contrast(86%);
}

.pi-arrow-back {
  background-image: url("../../assets/tabler-icons/arrow-back.svg");
  width: 16px;
  height: 16px;
  background-size: 16px 16px;
}

/* SKELETON */

.p-component,
.p-component *,
.p-skeleton,
.skeleton * {
  box-sizing: border-box;
}

.p-skeleton {
  background-color: #e9ecef;
  border-radius: 3px;
}
.p-skeleton:after {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0)
  );
}

/* METERGROUP */
.p-metergroup-meters {
  display: flex;
  background-color: $gray-200;
  border-radius: 4px;
  height: 4px;

  .p-metergroup-meter {
    height: 4px;

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}

