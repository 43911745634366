@import "fonts";
@import "shadows";

/* form inputs */
@mixin input-style {
  font-size: 14px;
  border: 1px solid $gray-300;
  border-radius: 3px;
  padding: 8px 12px;
  background: #fff;
  color: $gray-900;
}

@mixin input-style-hover {
  border: 1px solid $primary-normal;
}

@mixin input-style-focus {
  box-shadow: none;
  outline: none;
  border: 1px solid $primary-normal !important;
  box-shadow: 0px 0px 0px 2.8px #bbdefb;
}

@mixin input-style-disabled {
  background: $gray-100;
  border: 1px solid $gray-300;
  color: $gray-500;
}

@mixin input-style-invalid {
  border: 1px solid $danger-normal;
}

@mixin input-style-small {
  @include text-xs();
  padding: 7px 12px;
}

@mixin input-style-large {
  @include text-base();
  padding: 10px 12px;
}

@mixin select-style {
  @include text-sm();
  border: 1px solid $gray-300;
  border-radius: 3px;
  padding: 8px 12px;
}

@mixin select-style-small {
  @include text-xs();
}

@mixin select-style-large {
  @include text-base();
}

/* slider */
@mixin sizable-slider-label($width-value) {
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;

  p-inputnumber {
    margin-left: 0.5em;
    margin-right: 0.5em;

    .p-inputnumber {
      width: $width-value;
    }

    .p-inputnumber-input {
      text-align: center;
      font-weight: bold;
      width: 100%;
    }
  }
}

/* checkboxes */
.p-checkbox {
  .p-checkbox-box {
    width: 20px;
    height: 20px;
    border: 2px solid $gray-500;
    border-radius: 3px;
    transition:
      background-color 0.2s,
      color 0.2s,
      border-color 0.2s,
      box-shadow 0.2s;
    margin-right: 4px;
    background-color: $white;

    .p-checkbox-icon {
      transition-duration: 0.2s;
      color: $white;
      font-size: 14px;
    }

    &:hover {
      border-color: $primary-light;
    }

    &.p-highlight {
      border-color: $primary-normal;
      background: $primary-normal;

      &:hover {
        border-color: $primary-light;
        background: $primary-light;
      }
    }
  }

  &.p-checkbox-disabled {
    .p-checkbox-box {
      background: #eee;
      border-color: $gray-300;

      &.p-highlight {
        border-color: $gray-500;
        background-color: $gray-500;
      }
    }
  }

  &:not(.p-checkbox-disabled) {
    .p-checkbox-box {
      &.p-focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #a6d5fa;
        border-color: #2196f3;
      }
    }
  }
}

.p-checkbox-label-left {
  p-checkbox {
    flex-direction: row-reverse;

    .p-checkbox-label {
      margin-right: 7px;
    }
  }
}

.p-checkbox-label {
  margin-left: 7px;
  margin-bottom: 0;
}

/* Radio buttons */
.p-radiobutton {
  width: 20px;
  height: 20px;

  .p-radiobutton-box {
    border: 2px solid $gray-500;
    background: #ffffff;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    transition:
      background-color 0.2s,
      color 0.2s,
      border-color 0.2s,
      box-shadow 0.2s;

    &:not(.p-disabled):not(.p-highlight):hover {
      border-color: $primary-light;
    }

    &:not(.p-disabled).p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem #a6d5fa;
      border-color: $primary-light;
    }

    .p-radiobutton-icon {
      width: 9px;
      height: 9px;
      transition-duration: 0.2s;
      background-color: $white;
    }

    &.p-highlight {
      border-color: $primary-light;
      background: #fff;

      .p-radiobutton-icon {
        background-color: $primary-light;
      }

      &:not(.p-disabled):hover {
        border-color: $primary-light;
        background: #fff;
        color: $white;

        .p-radiobutton-icon {
          background-color: $primary-light;
        }
      }
    }
  }

  &.p-radiobutton-disabled {
    .p-radiobutton-box {
      background: $gray-200;
      border-color: $gray-300;
    }
  }
}

.p-radiobutton-label {
  margin-left: 8px;
  margin-bottom: 0;
}

p-radiobutton {
  &.ng-invalid {
    .p-radiobutton {
      .p-radiobutton-box {
        border-color: $danger-normal;
      }
    }
  }
}

/* Auto Complete */
.p-inputwrapper {
  &:hover {
    .p-autocomplete {
      border-color: $primary-normal;
    }
  }
  &.p-inputwrapper-focus {
    .p-autocomplete {
      border-color: $primary-normal;
    }
  }
}

.p-autocomplete {
  width: 100%;
  border-radius: 4px;

  .p-autocomplete-loader {
    right: 8px;
  }

  .p-autocomplete-input {
    width: 100%;

    &:enabled:hover {
      box-shadow: none;
    }

    &:enabled:focus {
      box-shadow: none;

      &:hover {
        box-shadow: none;
      }
    }
  }

  .p-autocomplete-dropdown {
    background-color: #fff;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &.p-autocomplete-dd {
    .p-autocomplete-loader {
      right: 2.857rem;
    }
  }

  .p-autocomplete-multiple-container {
    padding: 0.25rem 0.5rem;
  }

  .p-autocomplete-multiple-container:not(.p-disabled):hover {
    border-color: #2196f3;
  }

  .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
    border-color: #2196f3;
  }

  .p-autocomplete-multiple-container .p-autocomplete-input-token {
    padding: 0.25rem 0;
  }
  .p-autocomplete-multiple-container {
    .p-autocomplete-input-token {
      padding: 0.25rem 0.5rem;
      margin-right: 0.5rem;
      background: #e3f2fd;
      color: #495057;
      border-radius: 3px;

      input {
        font-size: 1rem;
        color: #495057;
        padding: 0;
        margin: 0;
      }

      .p-autocomplete-token-icon {
        margin-left: 0.5rem;
      }
    }
  }
}

p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext {
  border-color: $danger-normal;
}

.p-autocomplete-panel {
  background: #ffffff;
  color: $gray-900;
  border: 1px solid $gray-200;
  border-radius: 8px;
  @include shadow-4-medium();
  margin-top: 2px;

  max-width: -webkit-fill-available;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .p-autocomplete-items {
    .p-autocomplete-item {
      margin: 0;
      padding: 8px;
      border: 0 none;
      color: $gray-900;
      background: transparent;
      transition: box-shadow 0.2s;
      border-radius: 0;

      &:hover {
        background: $bg-primary;
      }

      &.p-highlight {
        background: $bg-primary;
      }
    }

    .p-autocomplete-empty-message {
      padding: 12px 8px;
      color: $gray-900;
      background: transparent;
    }

    .p-autocomplete-item-group {
      margin: 0;
      padding: 0.75rem 1rem;
      color: $gray-900;
      background: #ffffff;
      font-weight: 600;
    }
  }
}

.p-autocomplete {
  li.p-autocomplete-token {
    width: auto;
    margin: 0;
    border: 1px solid var(--sustainment-blue);
    font-size: 0.9rem;

    .p-autocomplete-token-label {
      width: auto;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

/* slider */
.p-slider {
  background: $gray-300;
  border: 0 none;
  border-radius: 3px;
}
.p-slider.p-slider-horizontal {
  height: 0.286rem;
}
.p-slider.p-slider-horizontal .p-slider-handle {
  margin-top: -0.5715rem;
  margin-left: -0.5715rem;
}
.p-slider.p-slider-vertical {
  width: 0.286rem;
}
.p-slider.p-slider-vertical .p-slider-handle {
  margin-left: -0.5715rem;
  margin-bottom: -0.5715rem;
}
.p-slider .p-slider-handle {
  height: 1.143rem;
  width: 1.143rem;
  background: #ffffff;
  border: 2px solid $primary-normal;
  border-radius: 50%;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
}
.p-slider .p-slider-handle:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem $primary-light;
}
.p-slider .p-slider-range {
  background: $primary-normal;
}
.p-slider:not(.p-disabled) .p-slider-handle:hover {
  background: $primary-normal;
  border-color: $primary-normal;
}
.p-slider.p-slider-animate.p-slider-horizontal .p-slider-handle {
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s,
    left 0.2s;
}
.p-slider.p-slider-animate.p-slider-horizontal .p-slider-range {
  transition: width 0.2s;
}
.p-slider.p-slider-animate.p-slider-vertical .p-slider-handle {
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s,
    bottom 0.2s;
}
.p-slider.p-slider-animate.p-slider-vertical .p-slider-range {
  transition: height 0.2s;
}

.sustainment-slider {
  .slider-label-s {
    @include sizable-slider-label(30px);
  }

  .slider-label-m,
  .slider-label {
    @include sizable-slider-label(70px);
  }

  .slider-label-l {
    @include sizable-slider-label(100px);
  }

  .slider-footer {
    display: flex;
    justify-content: space-between;
    padding-top: 0.4em;
    font-size: 12px;
    font-weight: 600;

    span {
      margin-bottom: 0;
    }
  }
}

/* Dropdown */
.p-dropdown {
  background: #ffffff;
  border: 1px solid $gray-300;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
  border-radius: 3px;
}
.p-dropdown:not(.p-disabled):hover {
  border-color: $primary-light;
}
.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none;
  outline: none;
  border: 1px solid $primary-normal !important;
  box-shadow: 0px 0px 0px 2.8px #bbdefb;
}
.p-dropdown.p-dropdown-clearable .p-dropdown-label {
  padding-right: 1.5rem;
}
.p-dropdown .p-dropdown-label {
  background: transparent;
  border: 0 none;
}
.p-dropdown .p-dropdown-label.p-placeholder {
  color: #6c757d;
}
.p-dropdown .p-dropdown-label:enabled:focus {
  outline: 0 none;
  box-shadow: none;
}
.p-dropdown .p-dropdown-trigger {
  background: transparent;
  color: #6c757d;
  width: 2.357rem;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.p-dropdown .p-dropdown-clear-icon {
  color: #6c757d;
  right: 2.357rem;
}

.p-dropdown-panel {
  background: #ffffff;
  color: $gray-900;
  border: 1px solid $gray-200;
  border-radius: 8px;
  @include shadow-4-medium();
  margin-top: 2px;

  .p-dropdown-header {
    padding: 0.5rem 1rem;
    border-bottom: 0 none;
    background: $gray-100;
    margin: 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;

    .p-dropdown-filter {
      padding-right: 1.5rem;
    }

    .p-dropdown-filter-icon {
      right: 0.5rem;
      color: #6c757d;
    }
  }

  .p-dropdown-items {
    .p-dropdown-item {
      margin: 0;
      padding: 8px;
      border: 0 none;
      color: $gray-900;
      background: transparent;
      transition: box-shadow 0.2s;
      border-radius: 0;

      &.p-highlight {
        color: $gray-900;
        background: $primary-lighter;
      }

      &:not(.p-highlight):not(.p-disabled):hover {
        color: $gray-900;
        background: #e9ecef;
      }
    }

    .p-dropdown-empty-message {
      padding: 0.5rem 1rem;
      color: $gray-900;
      background: transparent;
    }

    .p-dropdown-item-group {
      margin: 0;
      padding: 0.75rem 1rem;
      color: $gray-900;
      background: #ffffff;
      font-weight: 600;
    }
  }
}
p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
  border-color: $danger-normal;
}

.p-input-filled .p-dropdown {
  background: #f8f9fa;
}
.p-input-filled .p-dropdown:not(.p-disabled):hover {
  background-color: #f8f9fa;
}
.p-input-filled .p-dropdown:not(.p-disabled).p-focus {
  background-color: #ffffff;
}

/* Dropdown */
.p-dropdown {
  &.small {
    border-radius: 4px;

    .p-dropdown-label {
      &.p-inputtext {
        padding: 4px;
        @include text-caption-regular();
      }
    }

    .p-dropdown-trigger {
      width: auto;
      padding: 4px;
      .p-dropdown-trigger-icon {
        @include text-caption-regular();
        font-size: 10px;
      }
    }
  }

  &.added {
    background-color: $gray-200;
    border-color: $gray-200;

    .p-dropdown-label {
      &.p-inputtext {
        color: $gray-900;
      }
    }

    .p-dropdown-trigger {
      .p-dropdown-trigger-icon {
        color: $gray-900;
      }
    }
  }

  &.approved {
    background-color: $bg-success2;
    border-color: $bg-success2;
    color: $success-dark;

    .p-dropdown-label {
      &.p-inputtext {
        color: $success-dark;
      }
    }

    .p-dropdown-trigger {
      .p-dropdown-trigger-icon {
        color: $success-dark;
      }
    }
  }

  &.onboarding {
    background-color: $bg-attention2;
    border-color: $bg-attention2;
    color: $attention-dark;

    .p-dropdown-label {
      &.p-inputtext {
        color: $attention-dark;
      }
    }

    .p-dropdown-trigger {
      .p-dropdown-trigger-icon {
        color: $attention-dark;
      }
    }
  }

  &.donotuse {
    background-color: $bg-danger2;
    border-color: $bg-danger2;
    color: $danger-dark;

    .p-dropdown-label {
      &.p-inputtext {
        color: $danger-dark;
      }
    }

    .p-dropdown-trigger {
      .p-dropdown-trigger-icon {
        color: $danger-dark;
      }
    }
  }
}

.p-dropdown-panel {
  &.small {
    width: 180px;
    margin-top: 0;
    border-radius: 8px;
    border: 1px solid $gray-200;
    @include shadow-12-medium();
    .p-dropdown-items {
      .p-dropdown-item {
        padding: 6px 8px;
        color: $gray-900;

        &:hover {
          background-color: $gray-50;
        }

        &.p-highlight {
          background-color: $gray-50;
        }
      }

      p-dropdownitem {
        &:last-child {
          .p-dropdown-item {
            &:hover {
              border-bottom-left-radius: 8px;
              border-bottom-right-radius: 8px;
            }

            &.p-highlight {
              border-bottom-left-radius: 8px;
              border-bottom-right-radius: 8px;
            }
          }
        }

        &:first-child {
          .p-dropdown-item {
            &:hover {
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;
            }

            &.p-highlight {
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;
            }
          }
        }
      }
    }
  }
}
