@import "node_modules/sustainment-component/assets/styles/general.scss";

/* Notify Service Alerts Classes */
.alert-success {
  .toast-body {
    .button-OK {
      color: #5bcfff;
    }
  }
}

.alert-info {
  .toast-body {
    .button-OK {
      color: #5bcfff;
    }
  }
}

.alert-danger {
  max-width: 600px;
  .toast-body {
    .button-OK {
      color: #f55445;
    }
  }
}

.toast {
  color: white !important;
  background-color: #303336;
  opacity: 1 !important;

  width: auto;
  font-size: 16px;
  padding: 10px 30px;
}

.toast-fade {
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
  opacity: 0 !important;
  backdrop-filter: none !important;
  transition: all 0.5s linear;
}

.vendor-bottom-bar {
  height: auto !important;

  .p-sidebar-header {
    padding: 0;
  }

  .p-sidebar-content {
    padding: 0;

    .action-btn {
      display: none;
    }
  }
}

.vendor-modal-mobile {
  max-height: 100% !important;
  .ui-dialog-content {
    height: 100% !important;
  }
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-gap-half {
  display: flex;
  gap: 0.5rem;
}

.flex-gap-1 {
  display: flex;
  gap: 1rem;
}

.flex-gap-2 {
  display: flex;
  gap: 2rem;
}

.ml-auto {
  margin-left: auto;
}

.pointer-events-none {
  pointer-events: none;
}

.height-100 {
  height: 100%;
}

.width-100 {
  width: 100%;
}

.flex-justify-center {
  display: flex;
  justify-content: center;
}

.flex-only {
  display: flex;
}

.align-center-justify-end {
  align-items: center;
  justify-content: flex-end;
}

:focus-visible {
  outline: none;
}

.error-text {
  color: $danger-normal;
}

.warning-text {
  color: $attention-normal;
}

em {
  font-style: italic;
}
