@import "variables";

/* Modal */
/* Dialog */
.p-dialog {
  background-color: $white;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  width: $modal-width-sm;

  &.dialog-sm {
    width: $modal-width-sm;
  }

  &.dialog-md {
    width: $modal-width-md;
  }

  &.dialog-lg {
    width: $modal-width-lg;
  }

  &.dialog-xl {
    width: $modal-width-xl;
  }

  &.dialog-no-title {
    .p-dialog-content {
      margin-top: 30px;
      margin-bottom: 0;
    }
  }

  &.dialog-xl-sticked {
    width: calc(100% - 92px);
    margin-top: auto;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.dialog-no-title {
    &:has(.p-dialog-header) {
      .p-dialog-content {
        position: relative;
        margin-top: 30px;
      }
    }
    .p-dialog-header {
      border-bottom: none;
    }
  }

  &.dialog-no-footer {
    .p-dialog-content {
      margin-bottom: 0;
    }
  }

  .p-dialog-header {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    margin: 24px 24px 0 24px;
    border-bottom: 1px solid $gray-100;
    padding: 0 0 8px 0;

    .p-dialog-title {
      @include text-h6-bold();
      color: $gray-800;
    }

    .p-dialog-header-icon,
    .close {
      cursor: pointer;
      color: $gray-800;
      background: #fff;
    }
  }

  .p-dialog-content {
    position: relative;
    margin-top: 48px;
    margin-bottom: 60px;
    @include text-base();
    background: $white;

    .p-confirm-dialog-message {
      margin-top: 12px;
      margin-bottom: 12px;
    }
  }

  .p-dialog-body {
    .hint {
      @include text-sm();
      margin-bottom: 16px;
    }

    .intro {
      @include text-sm();
      color: $gray-500;
      padding-bottom: 16px;
    }
  }

  .p-dialog-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: $white;
    margin: 24px;
    text-align: right;

    button {
      &.secondary {
        margin-right: 12px;
      }
    }

    .buttons-action {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .p-confirm-dialog {
      width: $modal-width-sm;
    }

    .p-confirm-dialog-reject {
      @include secondary-button();
      margin-right: 8px;

      &:enabled:hover {
        @include secondary-button-hover();
      }

      &:enabled:active {
        @include secondary-button-active();
      }
    }
    .p-confirm-dialog-accept {
      @include primary-button();

      &:enabled:hover {
        @include primary-button-hover();
      }

      &:enabled:active {
        @include primary-button-active();
      }
    }
  }

  &.animation-modal {
    .p-dialog-header {
      border-bottom: none;
      justify-content: stretch;

      .p-dialog-title {
        flex: 1 0 0;
        @include text-h4-bold();
        text-align: center;
      }
    }
  }
}

.p-dialog-mask.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}
