/* grid layout */

@import "variables";

$prefix: "" !default;
$separator: "\\:" !default;

$gutter: 0.5rem !default;

$fieldMargin: 1rem !default;
$fieldLabelMargin: 0.5rem !default;
$helperTextMargin: 0.25rem !default;

$spacer: 1rem !default;

$breakpoints: (
  "xs": $break-xs,
  "sm": $break-sm,
  "md": $break-md,
  "lg": $break-lg,
  "xl": $break-xl,
) !default;

.#{$prefix}grid {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1 * $gutter;
  margin-left: -1 * $gutter;
  margin-top: -1 * $gutter;
}

.#{$prefix}grid > #{$prefix}.col,
.#{$prefix}grid > [class*="#{$prefix}col"] {
  box-sizing: border-box;
}

.#{$prefix}grid-nogutter {
  margin-right: 0;
  margin-left: 0;
  margin-top: 0;
}

.#{$prefix}grid-nogutter > .#{$prefix}col,
.#{$prefix}grid-nogutter > [class*="#{$prefix}col-"] {
  padding: 0;
}

.#{$prefix}col {
  flex-grow: 1;
  flex-basis: 0;
  padding: $gutter;
}

.#{$prefix}col-fixed {
  flex: 0 0 auto;
  padding: $gutter;
}

$grid-columns: (
  "col-1": 8.3333%,
  "col-2": 16.6667%,
  "col-3": 25%,
  "col-4": 33.3333%,
  "col-5": 41.6667%,
  "col-6": 50%,
  "col-7": 58.3333%,
  "col-8": 66.6667%,
  "col-9": 75%,
  "col-10": 83.3333%,
  "col-11": 91.6667%,
  "col-12": 100%,
);

$grid-column-offsets: (
  "col-offset-0": 0,
  "col-offset-1": 8.3333%,
  "col-offset-2": 16.6667%,
  "col-offset-3": 25%,
  "col-offset-4": 33.3333%,
  "col-offset-5": 41.6667%,
  "col-offset-6": 50%,
  "col-offset-7": 58.3333%,
  "col-offset-8": 66.6667%,
  "col-offset-9": 75%,
  "col-offset-10": 83.3333%,
  "col-offset-11": 91.6667%,
  "col-offset-12": 100%,
);

@each $col, $width in $grid-columns {
  .#{$prefix}grid .#{$prefix}#{$col} {
    flex: 0 0 auto;
    padding: $gutter;
    width: #{$width};
  }
}

@each $key, $val in $breakpoints {
  @media screen and (min-width: #{$val}) {
    .#{$prefix}grid .#{$key + $separator} {
      &#{$prefix}col {
        flex-grow: 1;
        flex-basis: 0;
        padding: $gutter;
      }

      &#{$prefix}col-fixed {
        flex: 0 0 auto;
        padding: $gutter;
      }

      @each $col, $width in $grid-columns {
        &#{$prefix}#{$col} {
          flex: 0 0 auto;
          padding: $gutter;
          width: #{$width};
        }
      }
    }
  }
}

@mixin style-class($prop, $map, $responsive: false, $states: false) {
  @each $key, $val in $map {
    .#{$prefix}#{$key} {
      #{$prop}: #{$val} !important;
    }
  }

  @if ($states) {
    @each $key, $val in $map {
      .focus#{$separator} {
        &#{$prefix}#{$key}:focus {
          #{$prop}: #{$val} !important;
        }
      }

      .hover#{$separator} {
        &#{$prefix}#{$key}:hover {
          #{$prop}: #{$val} !important;
        }
      }

      .active#{$separator} {
        &#{$prefix}#{$key}:active {
          #{$prop}: #{$val} !important;
        }
      }
    }
  }

  @if ($responsive) {
    @each $key, $val in $breakpoints {
      @media screen and (min-width: #{$val}) {
        .#{$key + $separator} {
          @each $key, $val in $map {
            &#{$prefix}#{$key} {
              #{$prop}: #{$val} !important;
            }
          }

          @if ($states) {
            @each $key, $val in $map {
              &focus#{$separator} {
                &#{$prefix}#{$key}:focus {
                  #{$prop}: #{$val} !important;
                }
              }

              &hover#{$separator} {
                &#{$prefix}#{$key}:hover {
                  #{$prop}: #{$val} !important;
                }
              }

              &active#{$separator} {
                &#{$prefix}#{$key}:active {
                  #{$prop}: #{$val} !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

@include style-class("margin-left", $grid-column-offsets, true);
