.p-datatable {
  .p-datatable-wrapper {
    border: 1px solid $gray-200;
    border-radius: 12px;
  }

  .p-paginator-top {
    border-width: 0 0 1px 0;
    border-radius: 0;
  }

  .p-paginator-bottom {
    border-width: 0 0 1px 0;
    border-radius: 0;
  }

  .p-datatable-header {
    background: #f8f9fa;
    color: $gray-900;
    border: 1px solid $gray-200;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
  }

  .p-datatable-footer {
    background: #f8f9fa;
    color: $gray-900;
    border: 1px solid $gray-100;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
  }

  .p-datatable-thead {
    > tr {
      > th {
        text-align: left;
        padding: 16px 12px;
        border: 1px solid $gray-300;
        border-width: 0 0 1px 0;
        color: $gray-900;
        @include text-p1-bold();
        transition: box-shadow 0.2s;
        background-color: $white;

        &:first-child {
          border-top-left-radius: 12px;
        }

        &:last-child {
          border-top-right-radius: 12px;
        }

        &:hover {
          background-color: $gray-50;
        }

        &.right {
          text-align: right;
        }

        .head-with-filter {
          display: flex;
          align-items: center;

          .head-col-left {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            column-gap: 8px;
          }
        }

        .head-with-sort {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          column-gap: 4px;
        }

        .head-with-sort-align-right {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          column-gap: 4px;
        }

        .head-with-filter-align-right {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          column-gap: 2px;
        }

        &.name,
        &.owner {
          width: 30%;
        }
      }
    }
  }

  .p-datatable-tfoot > tr > td {
    text-align: left;
    padding: 0px 12x;
    height: 48px;
    border: 1px solid $gray-300;
    border-width: 0 0 1px 0;
    font-weight: 700;
    color: $gray-900;
    background: #fff;
  }

  .p-datatable-tbody {
    > tr {
      color: $gray-900;
      transition: box-shadow 0.2s;
      outline-color: #a6d5fa;

      &:hover {
        td {
          background: $gray-50;
        }
      }

      &:last-child {
        > td {
          border-width: 0;
          &:first-child {
            border-bottom-left-radius: 12px;
          }

          &:last-child {
            border-bottom-right-radius: 12px;
          }
        }
      }

      &.p-rowgroup-header {
        > td {
          @include text-p2-regular();
          background-color: $gray-100;
          font-weight: 700;
        }
      }

      > td {
        text-align: left;
        border: 1px solid $gray-100;
        border-width: 0 0 1px 0;
        padding: 8px 12px;
        color: $gray-900;
        @include text-p1-regular();
        background-color: $white;

        &.right {
          text-align: right;

          @media screen and (max-width: $break-sm) {
            text-align: left;
          }
        }

        .p-column-title {
          font-weight: bold;
        }

        .value {
          @include text-p1-regular();
        }

        .p-row-toggler,
        .p-row-editor-init,
        .p-row-editor-save,
        .p-row-editor-cancel {
          width: 2rem;
          height: 2rem;
          color: #6c757d;
          border: 0 none;
          background: transparent;
          border-radius: 50%;
          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

          &:enabled:hover {
            color: #495057;
            border-color: transparent;
            background: #e9ecef;
          }
        }
      }

      @media screen and (max-width: $break-sm) {
        &:first-child {
          > td {
            &:first-child {
              border-top-left-radius: 12px;
              border-top-right-radius: 12px;
            }
          }
        }

        &:last-child {
          > td {
            &:last-child {
              border-bottom-left-radius: 12px;
            }
          }
        }
      }
    }
  }

  .p-sortable-column {
    .p-icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $gray-500;
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      padding: 2px;

      &:hover {
        color: $primary-normal;
        background-color: $bg-primary;
      }
    }

    .p-sortable-column-badge {
      width: 28px;
      height: 28px;
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      color: $gray-500;
      background: #e3f2fd;
      margin-left: 0.5rem;
    }

    &.p-highlight {
      background-color: $cold-smoke;

      &:hover {
        background-color: $cold-smoke;
      }

      .p-sortable-column-icon {
        color: $primary-normal;

        &:hover {
          color: $primary-normal;
          background-color: $bg-secondary;
        }
      }

      .p-column-filter-menu-button {
      }

      .p-column-filter-menu-button-active {
        color: $white;

        &:hover {
          color: $white;
          background-color: $primary-normal;
        }
      }
    }

    &:focus {
      outline: 0 none;
    }
  }
}

.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
  margin-right: 0.5rem;
}
.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #e3f2fd;
  color: #495057;
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
  box-shadow: inset 0 2px 0 0 #e3f2fd;
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
  box-shadow: inset 0 -2px 0 0 #e3f2fd;
}
.p-datatable.p-datatable-hoverable-rows
  .p-datatable-tbody
  > tr:not(.p-highlight):hover {
  background: #e9ecef;
  color: #495057;
}
.p-datatable .p-column-resizer-helper {
  background: $primary-normal;
}
.p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
  background: #f8f9fa;
}
.p-datatable .p-datatable-loading-icon {
  font-size: 2rem;
}
.p-datatable.p-datatable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-footer {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-top {
  border-width: 0 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-bottom {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  border-width: 1px 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
  border-width: 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
  border-width: 1px;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
  background: #fcfcfc;
}
.p-datatable.p-datatable-striped
  .p-datatable-tbody
  > tr:nth-child(even).p-highlight {
  background: #e3f2fd;
  color: #495057;
}
.p-datatable.p-datatable-striped
  .p-datatable-tbody
  > tr:nth-child(even).p-highlight
  .p-row-toggler {
  color: #495057;
}
.p-datatable.p-datatable-striped
  .p-datatable-tbody
  > tr:nth-child(even).p-highlight
  .p-row-toggler:hover {
  color: #495057;
}
.p-datatable.p-datatable-sm .p-datatable-header {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-footer {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-lg .p-datatable-header {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-footer {
  padding: 1.25rem 1.25rem;
}

.p-dataview .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-dataview .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-dataview .p-dataview-header {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #e9ecef;
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-dataview .p-dataview-content {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  padding: 0;
}
.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
  border: solid #e9ecef;
  border-width: 0 0 1px 0;
}
.p-dataview .p-dataview-footer {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.p-dataview .p-dataview-loading-icon {
  font-size: 2rem;
}
.p-dataview .p-dataview-emptymessage {
  padding: 1rem;
}

.p-column-filter-row .p-column-filter-menu-button,
.p-column-filter-row .p-column-filter-clear-button {
  margin-left: 0.5rem;
}

.p-column-filter-menu-button {
  font-size: 12px;
  width: 20px;
  height: 20px;
  color: $gray-500;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-column-filter-menu-button:hover {
  color: $primary-normal;
  border-color: transparent;
  background: $bg-primary;
}
.p-column-filter-menu-button.p-column-filter-menu-button-open,
.p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
  background: $primary-normal;
  color: $white;
}
.p-column-filter-menu-button.p-column-filter-menu-button-active,
.p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
  background: $primary-normal;
  color: $white;
}
.p-column-filter-menu-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem $bg-primary;
}

.p-column-filter-clear-button {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-column-filter-clear-button:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}
.p-column-filter-clear-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-column-filter-overlay {
  background: #ffffff;
  color: $gray-900;
  border: 0 none;
  border-radius: 8px;
  @include card-shadow();
  min-width: 200px;

  .p-column-filter-row-items {
    padding: 8px 0;

    .p-column-filter-row-item {
      margin: 0;
      padding: 8px 16px;
      border: 0 none;
      color: $gray-900;
      background: transparent;
      transition: box-shadow 0.2s;
      border-radius: 0;

      &.p-highlight {
        color: #495057;
        background: #e3f2fd;
      }

      &:not(.p-highlight):not(.p-disabled):hover {
        color: #495057;
        background: #e9ecef;
      }

      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: inset 0 0 0 0.15rem #a6d5fa;
      }
    }

    .p-column-filter-separator {
      border-top: 1px solid #dee2e6;
      margin: 0.25rem 0;
    }
  }
}

.p-column-filter-overlay-menu {
  .p-column-filter-operator {
    padding: 16px 16px 0 16px;
    border-bottom: 0 none;
    color: $gray-800;
    margin: 0;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  .p-column-filter-constraint {
    padding: 1rem;
    border-bottom: 1px solid $gray-200;

    .p-column-filter-matchmode-dropdown {
      margin-bottom: 0.5rem;
    }

    .p-column-filter-remove-button {
      margin-top: 0.5rem;
      @include tertiary-button();
      @include small-button();

      &:hover {
        @include tertiary-button-hover();
      }
    }

    &:last-child {
      border-bottom: 0 none;
    }
  }

  .p-column-filter-add-rule {
    padding: 0 16px;

    .p-column-filter-add-button {
      @include tertiary-button();
      @include small-button();

      &:hover {
        @include tertiary-button-hover();
      }
    }
  }

  .p-column-filter-buttonbar {
    padding: 16px;

    .p-button {
      @include primary-button();
      @include small-button();

      &:hover {
        @include primary-button-hover();
      }

      &.p-button-outlined {
        @include tertiary-button();
        @include small-button();

        &:hover {
          @include tertiary-button-hover();
        }
      }
    }
  }
}
