@import "fonts";

/* Primary */
@mixin primary-button {
  @include text-sm();
  background: $primary-normal;
  border: 1px solid $primary-normal;
  color: $white;
  padding: 8px 16px;
  border-radius: 4px;
}

@mixin primary-button-hover {
  background: $primary-light;
  border: 1px solid $primary-light;
}

@mixin primary-button-focus {
  outline: 0 none;
  outline-offset: 0;
  border: 1px solid #fff;
  box-shadow: 0 0 0 2px $primary-light;
}

@mixin primary-button-active {
  background: $primary-dark;
  border: 1px solid $primary-dark;
}

@mixin primary-button-disabled {
  border: 1px solid $gray-500 !important;
  background-color: $gray-500 !important;
  transition: none !important;
}

/* Secondary */
@mixin secondary-button {
  @include primary-button();
  background: none;
  border: 1px solid $primary-normal;
  color: $primary-normal;
}

@mixin secondary-button-hover {
  color: $primary-light;
  background: $cold-smoke;
  border: 1px solid $primary-light;
}

@mixin secondary-button-active {
  color: $primary-dark;
  background: $cold-smoke;
  border: 1px solid $primary-dark;
}

@mixin secondary-button-focus {
  outline: 0 none;
  outline-offset: 0;
  border: 1px solid $primary-normal;
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px $primary-light;
}

@mixin secondary-button-disabled {
  border: 1px solid $gray-500;
  color: $gray-500;
}

/* Text Button */
@mixin tertiary-button {
  @include text-sm();
  @include secondary-button();
  border-color: transparent;
}

@mixin tertiary-button-hover {
  color: $primary-light;
  background-color: $bg-information;
  border-color: $bg-information;
}

@mixin tertiary-button-active {
  color: $primary-dark;
  background-color: $cold-smoke;
  border-color: transparent;
}

@mixin tertiary-button-focus {
  outline: 0 none;
  outline-offset: 0;
  border: 1px solid #fff;
  box-shadow: 0 0 0 2px $primary-light;
}

@mixin tertiary-button-disabled {
  color: $gray-500;
}

/* SIZE */
@mixin small-button {
  @include text-xs();
  padding: 8px 12px;
}

@mixin large-button {
  @include text-base();
  padding: 10px 16px;
}

/* Primary RED SKIN */

@mixin primary-red-skin-button {
  background: $danger-normal;
  border: 1px solid $danger-normal;
}

@mixin primary-red-skin-button-hover {
  background: $danger-light;
  border-color: $danger-light;
}

@mixin primary-red-skin-button-active {
  background: $danger-dark;
  border-color: $danger-dark;
}

@mixin primary-red-skin-button-focus {
  box-shadow: 0 0 0 2px $danger-light;
}

/* Primary GREEN SKIN */

@mixin primary-green-skin-button {
  background: $success-normal;
  border: 1px solid $success-normal;
}

@mixin primary-green-skin-button-hover {
  background: $success-light;
  border-color: $success-light;
}

@mixin primary-green-skin-button-active {
  background: $success-dark;
  border-color: $success-dark;
}

@mixin primary-green-skin-button-focus {
  box-shadow: 0 0 0 2px $success-light;
}

/* Primary ORANGE SKIN */

@mixin primary-orange-skin-button {
  background: $attention-normal;
  border: 1px solid $attention-normal;
}

@mixin primary-orange-skin-button-hover {
  background: $attention-light;
  border-color: $attention-light;
}

@mixin primary-orange-skin-button-active {
  background: $attention-dark;
  border-color: $attention-dark;
}

@mixin primary-orange-skin-button-focus {
  box-shadow: 0 0 0 2px $attention-light;
}

/* Primary INFO SKIN */

@mixin primary-info-skin-button {
  background: transparent;
  border-color: transparent;
  color: $primary-normal;
}

@mixin primary-info-skin-button-hover {
  background: transparent;
  border-color: transparent;
  color: $primary-light;
}

@mixin primary-info-skin-button-active {
  background: $bg-primary;
  border: 1px solid $primary-normal;
  color: $primary-normal;
}

@mixin primary-info-skin-button-focus {
  border-color: #fff;
  box-shadow: 0 0 0 2px $primary-light;
}

/* Secondary RED SKIN */

@mixin secondary-red-skin-button {
  color: $danger-normal;
  border: 1px solid $danger-normal;
  background-color: #fff;
}

@mixin secondary-red-skin-button-hover {
  border-color: $danger-light;
  color: $danger-light;
  background-color: $bg-danger;
}

@mixin secondary-red-skin-button-active {
  color: $danger-dark;
  border-color: $danger-dark;
}

@mixin secondary-red-skin-button-focus {
  border-color: $danger-normal;
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px $danger-light;
}

/* Secondary GREEN SKIN */

@mixin secondary-green-skin-button {
  color: $success-normal;
  border: 1px solid $success-normal;
  background-color: #fff;
}

@mixin secondary-green-skin-button-hover {
  color: $success-light;
  border-color: $success-light;
  background-color: $bg-success;
}

@mixin secondary-green-skin-button-active {
  color: $success-dark;
  border-color: $success-dark;
}

@mixin secondary-green-skin-button-focus {
  border-color: $success-normal;
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px $success-light;
}

/* Secondary ORANGE SKIN */

@mixin secondary-orange-skin-button {
  color: $attention-normal;
  border: 1px solid $attention-normal;
  background-color: #fff;
}

@mixin secondary-orange-skin-button-hover {
  color: $attention-light;
  border-color: $attention-light;
  background-color: $bg-attention;
}

@mixin secondary-orange-skin-button-active {
  color: $attention-dark;
  border-color: $attention-dark;
}

@mixin secondary-orange-skin-button-focus {
  border-color: $attention-normal;
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px $attention-light;
}

/* Text RED SKIN Button */
@mixin tertiary-red-skin-button {
  color: $danger-normal;
  border: none;
}

@mixin tertiary-red-skin-button-hover {
  color: $danger-light;
  background-color: $bg-danger;
}

@mixin tertiary-red-skin-button-active {
  color: $danger-dark;
}

@mixin tertiary-red-skin-button-focus {
  box-shadow: 0 0 0 2px $danger-light;
}

/* Text GREEN SKIN Button */
@mixin tertiary-green-skin-button {
  color: $success-normal;
  border: none;
}

@mixin tertiary-green-skin-button-hover {
  color: $success-light;
  background-color: $bg-success;
}

@mixin tertiary-green-skin-button-active {
  color: $success-dark;
}

@mixin tertiary-green-skin-button-focus {
  box-shadow: 0 0 0 2px $success-light;
}

/* Text ORANGE SKIN Button */
@mixin tertiary-orange-skin-button {
  color: $attention-normal;
  border: none;
}

@mixin tertiary-orange-skin-button-hover {
  color: $attention-light;
  background-color: $bg-attention;
}

@mixin tertiary-orange-skin-button-active {
  color: $attention-dark;
}

@mixin tertiary-orange-skin-button-focus {
  box-shadow: 0 0 0 2px $attention-light;
}

/* Icon only */

@mixin icon-button {
  padding: 8px 10px !important;
}
@mixin icon-button-large {
  padding: 10px 12px !important;
}

/* Invalid */
@mixin invalid {
  background: $danger-lighter;
  border: 1px solid $danger-lighter;
  color: $danger-normal;
}

@mixin invalid-hover {
  background: #ffe5db;
  border: 1px solid #ffe5db;
  color: $danger-normal;
}

@mixin invalid-active {
  background: #ffd5c4;
  border: 1px solid #ffd5c4;
  color: $danger-normal;
}

/* Dropdown button */
@mixin dropdown-button {
  background: $white;
  border: 1px solid $gray-300;
  color: $gray-500;
  padding: 8px 12px;
  border-radius: 3px;
}

@mixin dropdown-button-hover {
  background: $gray-200;
  border: 1px solid $gray-300;
  color: $gray-500;
}

@mixin dropdown-button-active {
  border: 1px solid $primary-normal;
  color: $gray-500;
  box-shadow: 0px 0px 0px 2.8px #bbdefb;
  padding: 7px 12px;
}

@mixin dropdown-button-hasvalue {
  border: 1px solid $primary-normal;
  background-color: $cold-smoke;
  color: $primary-normal;
  padding: 7px 12px;
}
