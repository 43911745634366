@import "fonts";
@import "shadows";

/* Messages */

.p-message {
  margin: 12px 0;
  border-radius: 6px;
  filter: drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.06))
    drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.04))
    drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.04));

  .p-message-wrapper {
    padding: 16px 24px;

    .p-message-custom-wrapper {
      display: flex;

      .p-message-content {
        display: flex;
        column-gap: 8px;
      }
    }

    .p-message-action {
      display: flex;
      align-items: center;
      margin-top: 8px;
    }
  }

  .p-message-close {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: transparent;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

    &:hover {
      background: rgba(255, 255, 255, 0.3);
    }

    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem #a6d5fa;
    }
  }

  &.p-message-info {
    background: $bg-primary;
    border: solid $information-dark;
    border-width: 0 0 0 6px;
    color: $information-dark;

    .p-message-icon {
      color: $information-dark;
    }

    .p-message-close {
      color: $information-dark;
    }
  }

  &.p-message-success {
    background: $bg-success;
    border: solid $success-dark;
    border-width: 0 0 0 6px;
    color: $success-dark;

    .p-message-icon {
      color: $success-dark;
    }

    .p-message-close {
      color: $success-dark;
    }
  }

  &.p-message-warn {
    background: $bg-attention;
    border: solid $attention-dark;
    border-width: 0 0 0 6px;
    color: $attention-dark;

    .p-message-icon {
      color: $attention-dark;
    }

    .p-message-close {
      color: $attention-dark;
    }
  }

  &.p-message-error {
    background: $bg-danger;
    border: solid $danger-dark;
    border-width: 0 0 0 6px;
    color: $danger-dark;

    .p-message-icon {
      color: $danger-dark;
    }

    .p-message-close {
      color: $danger-dark;
    }
  }

  .p-message-text {
    font-size: 1rem;
  }

  .p-message-icon {
    font-size: 1.5rem;
    margin-right: 12px;
  }

  .p-message-summary {
    font-weight: 700;
  }

  .p-message-detail {
    margin-left: 0.5rem;
  }
}

.p-inline-message {
  padding: 8px 12px;
  margin: 0;
  border-radius: 6px;

  &.p-inline-message-info {
    background: $bg-information;
    border: solid $information-dark;
    border-width: 0px;
    color: $information-dark;

    .p-inline-message-icon {
      color: $information-dark;
    }
  }

  &.p-inline-message-success {
    background: $bg-success;
    border: solid $success-dark;
    border-width: 0px;
    color: $success-dark;

    .p-inline-message-icon {
      color: $success-dark;
    }
  }

  &.p-inline-message-warn {
    background: $bg-attention;
    border: solid $attention-dark;
    border-width: 0px;
    color: $attention-dark;

    .p-inline-message-icon {
      color: $attention-dark;
    }
  }

  &.p-inline-message-error {
    background: $bg-danger;
    border: solid $danger-dark;
    border-width: 0px;
    color: $danger-dark;
  }

  .p-inline-message-icon {
    color: $danger-dark;
  }

  .p-inline-message-icon {
    font-size: 1rem;
    margin-right: 0.5rem;
  }

  .p-inline-message-text {
    font-size: 1rem;
  }

  &.p-inline-message-icon-only .p-inline-message-icon {
    margin-right: 0;
  }
}

.p-toast {
  width: 475px !important;

  @media only screen and (max-width: $break-md) {
    width: 425px !important;
  }

  @media only screen and (max-width: $break-sm) {
    width: 345px !important;
  }

  @media only screen and (max-width: $break-xs) {
    width: auto !important;
    left: 20px;
  }

  &.p-toast-top-right {
    top: 80px;
  }

  .p-toast-message {
    margin: 0 0 4px 0;
    @include shadow-12-medium();
    border-radius: 6px;

    .p-toast-message-content {
      padding: 16px 24px;
      border-width: 0 0 0 6px;
      column-gap: 8px;

      .p-toast-message-text {
        display: flex;
        align-items: center;
        column-gap: 8px;
      }

      .p-toast-message-icon {
        font-size: 1.5rem;
      }

      .p-toast-summary {
        font-weight: 700;
      }

      .p-toast-detail {
        margin: 0;
      }
    }

    .p-toast-icon-close {
      justify-content: flex-end;
      align-items: flex-start;
      width: 2rem;
      background: transparent;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      margin-top: 4px;

      &:focus {
        outline: 0 none;
        outline-offset: 0;
      }
    }

    &.p-toast-message-info {
      background: $bg-primary;
      border: solid $information-dark;
      border-width: 0 0 0 6px;
      color: $information-dark;

      .p-toast-message-icon,
      .p-toast-icon-close {
        color: $information-dark;
      }
    }

    &.p-toast-message-success {
      background: $bg-success;
      border: solid $success-dark;
      border-width: 0 0 0 6px;
      color: $success-dark;

      .p-toast-message-icon,
      .p-toast-icon-close {
        color: $success-dark;
      }
    }

    &.p-toast-message-warn {
      background: $bg-attention;
      border: solid $attention-dark;
      border-width: 0 0 0 6px;
      color: $attention-dark;

      .p-toast-message-icon,
      .p-toast-icon-close {
        color: $attention-dark;
      }
    }

    &.p-toast-message-error {
      background: $bg-danger;
      border: solid $danger-dark;
      border-width: 0 0 0 6px;
      color: $danger-dark;

      .p-toast-message-icon,
      .p-toast-icon-close {
        color: $danger-dark;
      }
    }
  }
}
