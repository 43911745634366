@mixin shadow-4-base {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
}

@mixin shadow-4-medium {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04);
}

@mixin shadow-4-large {
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.04);
}

@mixin shadow-4-xlarge {
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04);
}

@mixin shadow-12-base {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
}

@mixin shadow-12-medium {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12);
}

@mixin shadow-12-large {
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.12);
}

@mixin shadow-12-xlarge {
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.12);
}
