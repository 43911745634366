/* GENERAL */

/* for firefox */
:root {
  scrollbar-color: transparent !important;
  scrollbar-width: thin !important;
}

/* Scroll bar stylings */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.35);
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.35);
}

/* Scroll panel */

/* scrollpanel */
.p-scrollpanel {
  .p-scrollpanel-content {
    width: calc(100% + 16px);
    height: calc(100% + 16px);
    padding: 0 16px 16px 0;
  }
  .p-scrollpanel-bar {
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.35);
  }
  .p-scrollpanel-bar-y {
    width: 8px;
  }
}
